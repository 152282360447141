<template>
    <div class="wrapper">
        <Navbar />
        <div class="options">
            <div class="options__container">
                <div class="options__header">
                    <h1 class="options__title">
                        Hi, Welcome
                    </h1>
                    <div class="options__subtitles">
                        <p class="options__subtitle">
                            Picking a course allows you to create structured
                            related and grouped content expressed as lessons.
                        </p>
                    </div>
                </div>
                <div class="options__cards">
                    <router-link to="/courses/create" class="card">
                        <div class="card__content">
                            <img src="@/assets/img/course.png"
                                 class="card__image"
                            >
                            <h3 class="card__title">
                                Create Tutorial
                            </h3>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '@/components/ui/Navbar.vue';

export default {
    components: {
        Navbar,
    },
    computed: {
        ...mapGetters('courses', ['courses']),
    },
    created() {
        if (this.courses && this.courses.length > 0) {
            this.$router.push('/courses/dashboard');
        }
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply w-full;
    @apply h-auto;
}

.options {
    @apply relative;
    @apply h-auto;
    @apply mt-16;
    @apply mx-auto;
    @apply flex-1;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    min-height: calc(100vh - 4rem);
    &__container {
        width: 70%;
        height: 70%;
        @apply flex;
        @apply flex-col;
        @apply flex-col;
        @apply items-center;
        @apply justify-between;
    }

    &__title {
        @apply mb-8;
        @apply text-center;
        @apply text-gray-900;
        @apply text-4xl;
        @apply font-semibold;
    }

    &__subtitles {
        @apply flex;
        @apply flex-col;
    }
    &__subtitle {
        @apply font-medium;
        @apply text-center;
        @apply text-gray-700;
    }

    &__cards {
        @apply w-full;
        @apply flex-1;
        @apply flex;
        @apply justify-around;
        @apply mt-12;
        @apply items-center;
    }
}
.card {
    height: 40vh;
    width: 40vh;
    @apply flex;
    text-decoration: none;
    @apply justify-center;
    @apply items-center;
    @apply text-xl;
    @apply font-medium;
    @apply text-gray-700;
    @apply rounded-md;
    @apply bg-white;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
    &__content {
        @apply h-full;
        @apply w-full;
        @apply flex;
        @apply flex-col;
        @apply justify-around;
        @apply items-center;
    }
    &__image {
        height: 50%;
    }
    &__title {
        @apply text-gray-900;
        @apply text-xl;
        @apply font-semibold;
    }
    &:hover {
        @apply shadow-md;
        transform: scale(1.02);
    }
}
</style>
